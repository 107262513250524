import React, { useEffect, Suspense } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ReactGA from "react-ga4";

import BaseLayout from "./layouts/BaseLayout/BaseLayout";
import LoadingBox from "./components/LoadingBox/LoadingBox";
import app_params from "./config/params";
// import HomeScreen from "./screens/HomeScreen/HomeScreen";
const HomeScreen = React.lazy(() => import('./screens/HomeScreen/HomeScreen'));
// import AdvancedSearchScreen from "./screens/AdvancedSearchScreen/AdvancedSearchScreen";
const AdvancedSearchScreen = React.lazy(() => import('./screens/AdvancedSearchScreen/AdvancedSearchScreen'));
// import SearchResultsScreen from "./screens/SearchResultsScreen/SearchResultsScreen";
const SearchResultsScreen = React.lazy(() => import('./screens/SearchResultsScreen/SearchResultsScreen'));
// import RecordScreen from "./screens/RecordScreen/RecordScreen";
const RecordScreen = React.lazy(() => import('./screens/RecordScreen/RecordScreen'));
// import NotFoundScreen from "./screens/NotFoundScreen/NotFoundScreen";
const NotFoundScreen = React.lazy(() => import('./screens/NotFoundScreen/NotFoundScreen'));
// import AcquisitionScreen from "./screens/AcquisitionScreen/AcquisitionScreen";
const AcquisitionScreen = React.lazy(() => import('./screens/AcquisitionScreen/AcquisitionScreen'));
// import AboutUsScreen from "./screens/AboutUsScreen/AboutUsScreen";
const AboutUsScreen = React.lazy(() => import('./screens/AboutUsScreen/AboutUsScreen'));
// import PolicyUNIS from "./screens/Policies/PolicyUNIS";
const PolicyUNIS = React.lazy(() => import('./screens/Policies/PolicyUNIS'));
const PolicyBNG = React.lazy(() => import('./screens/Policies/PolicyBNG'));
const PolicyUMG = React.lazy(() => import('./screens/Policies/PolicyUMG'));

const App = () => {

  useEffect(() => {
    ReactGA.initialize(app_params.gaTag);

    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter basename="/opac">
      <BaseLayout>
        {/* Common routes */}
        <Suspense fallback={<LoadingBox />}>
          <Routes>
            <Route exact path="/" element={<HomeScreen />} />
            <Route exact path="/acquisitions" element={<AcquisitionScreen />} />
            <Route exact path="/aboutus" element={<AboutUsScreen />} />
            <Route exact path="/advancedsearch" element={<AdvancedSearchScreen />} />
            <Route exact path="/query" element={<SearchResultsScreen />} />
            <Route path="/record/:id" element={<RecordScreen />} />
            <Route path="/policies/unis" element={<PolicyUNIS />} />
            <Route path="/policies/bng" element={<PolicyBNG />} />
            <Route path="/policies/umg" element={<PolicyUMG />} />
            <Route path='/404' element={<NotFoundScreen />} />
            <Route exact path='*' element={<NotFoundScreen />} />
          </Routes>
        </Suspense>
      </BaseLayout>
    </BrowserRouter>
  );
};

export default App;